import { useEffect } from 'react';
import { gsap } from 'gsap';

type Props = {
    src: string;
};

export const SpotifyIframe = ({ src }: Props) => {
    useEffect(() => {
        gsap.fromTo('.spotify-iframe', { opacity: 0 }, { opacity: 1, duration: 0.5 });
        return () => {
            gsap.to('.spotify-iframe', { opacity: 0, duration: 0.2 });
        };
    }, [src]);

    return (
        <iframe
            className="spotify-iframe"
            title="spotify"
            src={src}
            width="100%"
            height="152"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        />
    );
};
