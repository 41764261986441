import iconPrev from 'assets/img/testimonials-section/prev.svg';
import iconNext from 'assets/img/testimonials-section/next.svg';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { ReactSVG } from 'react-svg';

// Import Swiper styles
import 'swiper/css';
import { Tab, testimonialItem } from '../tabItem';

type TestimonialsListProps = {
    testimonials: testimonialItem[];
    setIndex: (index: number) => void;
    currentIndex: number;
};


export const TestimonialList = (props: TestimonialsListProps) => {

    const { testimonials, currentIndex } = props;
    return (
        <div className="testimonials-list testimonials-desktop">
            <div className="swiper">
                <Swiper
                    className='swiper-wrapper'
                    slidesPerView={5}
                    direction='vertical'
                    modules={[Navigation]}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                >
                    {testimonials?.map((item, index) => (
                        <SwiperSlide
                            key={`testimonials-item-${index}`}
                            onClick={() => props.setIndex((index))}
                        >
                            <Tab {...item} active={currentIndex === index} />
                        </SwiperSlide>
                    ))}
                </Swiper>

            </div>
            <div className="testimonials-navigation">
                <div className="swiper-button-prev">
                    <ReactSVG src={iconPrev} />
                </div>
                <div className="swiper-button-next">
                    <ReactSVG src={iconNext} />
                </div>
                <div className="testimonials-navigation__prev"></div>
                <div className="testimonials-navigation__next"></div>
            </div>
        </div>
    );
};
