import iconUser from 'assets/img/testimonials-section/user.jpg';


export type testimonialItem = {
    streams: number;
    listeners: number;
    title: string;
    name: string;
    type: string;
    url: string;
    text: string;
    song: string;
    icon?: string;
    active?: boolean;
};

export const Tab = (props: testimonialItem) => {
    const { name, streams, listeners, title, type, icon } = props;
    return (
        <div className="swiper-slide">
            <div
                className={`testimonials-item ${props.active ? 'active' : ''}`}
                data-streams={streams}
                data-listeners={listeners}
            >
                <div className="testimonials-item__img">
                    <img src={icon ? icon : iconUser} alt="artist icon" />
                </div>
                <div className="testimonials-item__info">
                    <p className="testimonials-item__title title-h8">
                        {title}
                    </p>
                    <p className="testimonials-item__text desc-3">
                        <span>{name}</span> <span>{type}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};
