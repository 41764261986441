
import { useCallback, useState } from 'react';
import { Easings } from 'scripts/utils/tweenHelpers';
import { inFrames } from 'scripts/utils/inFrames';

import { gsap } from 'gsap';
import { testimonialItem } from '../tabItem';
import { TestimonialList } from '../list';
import { TestimonialInformation } from '../informationBlock';


type Props = {
    testimonials: testimonialItem[];
    copy: any;
    inView: boolean,
};


export const DesktopTestimonials = ({ testimonials, copy, inView }: Props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const show = useCallback(async () => {
        return gsap.timeline()
            .to('.testimonials-api__item', {
                opacity: 1,
                scale: 1,
                duration: inFrames(25),
                ease: Easings.customEase,
            }, inFrames(10))
            .fromTo('.testimonials-song__item', {
                y: '10px',
            }, {
                y: 0,
                opacity: 1,
                scale: 1,
                duration: inFrames(25),
                ease: Easings.customEase,
            }, 0)
            .to('.testimonials-text__item', {
                opacity: 1,
                scale: 1,
                duration: inFrames(25),
                ease: Easings.customEase,
            }, 0);
    }, []);


    const hide = useCallback(async () => {
        return gsap.timeline()
            .to('.testimonials-api__item', {
                opacity: 0,
                scale: 0.9,
                duration: inFrames(25),
                ease: Easings.customEaseIn,
            })
            .to('.testimonials-song__item', {
                opacity: 0,
                y: '-10px',
                duration: inFrames(25),
                ease: Easings.customEaseIn,
            }, '<')
            .to('.testimonials-text__item', {
                opacity: 0,
                scale: 0.9,
                duration: inFrames(25),
                ease: Easings.customEaseIn,
            }, '<');
    }, []);

    const changeIndex = useCallback(async (index: number) => {
        await hide();

        setActiveIndex(index);

        await show();
    }, [hide, show]);

    return (
        <>
            <TestimonialList
                testimonials={testimonials}
                setIndex={changeIndex}
                currentIndex={activeIndex}
            />
            <TestimonialInformation
                currentTestimonial={testimonials[activeIndex]}
                copy={copy}
                inView={inView}
            />
        </>
    );
};

