import iconUser from 'assets/img/testimonials-section/user.jpg';
import { SpotifyIframe } from '../playerWrap';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import { useState } from 'react';
import { testimonialItem } from '../tabItem';
import { numberWithCommas } from 'scripts/utils/numberWithCommas';

type Props = {
    testimonials: testimonialItem[];
    copy: any;
};


export const TestimonialMobile = ({ testimonials, copy }: Props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    return (
        <div className="testimonials-slider">
            <div className="swiper">
                <div className="swiper-wrapper">
                    <Swiper
                        className='swiper-wrapper'
                        slidesPerView={1}
                        spaceBetween={15}
                        modules={[Pagination]}
                        pagination ={{
                            el: '.testimonials-slider .swiper-pagination',
                            clickable: true,
                        }}
                        onSlideChangeTransitionStart={(swiper) => {
                            setActiveIndex(swiper.activeIndex);
                        }}
                    >
                        {
                            testimonials?.map((item, index) => (
                                <SwiperSlide key={`testimonials-item-${index}`}>
                                    <MobileSlide
                                        testimonial={item}
                                        copy={copy}
                                        url={testimonials[activeIndex].url}
                                        active={activeIndex === index}
                                        icon={item.icon}
                                    />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
                <div className="swiper-pagination"></div>
            </div>
        </div>
    );
};


const MobileSlide = ({ testimonial, copy, url, active, icon }) => {


    return (
        <div className="swiper-slide">
            <div className="testimonials-information">
                <div className="testimonials-api">
                    <SpotifyIframe src={active ? url : ''} />
                </div>
                <div className="testimonials-grid">
                    <div className="testimonials-grid__item">
                        <p className="testimonials-grid__subtitle desc-3">
                            {copy.song}
                        </p>
                        <div className="testimonials-song testimonials-grid__title">
                            <div className="testimonials-song__inner">
                                {testimonial.song}
                            </div>
                        </div>
                    </div>
                    <div className="testimonials-grid__item">
                        <p className="testimonials-grid__subtitle desc-3">
                            {copy.streams}
                        </p>
                        <div className="testimonials-streams testimonials-grid__title">
                            {numberWithCommas(testimonial.streams)}
                        </div>
                    </div>
                    <div className="testimonials-grid__item">
                        <p className="testimonials-grid__subtitle desc-3">
                            <span className="testimonials-grid__subtitle-tablet">
                                {copy.listeners}
                            </span>
                            <span className="testimonials-grid__subtitle-mobile">
                                {copy.listenersMobile}
                            </span>
                        </p>
                        <div className="testimonials-listeners testimonials-grid__title">
                            {numberWithCommas(testimonial.listeners)}
                        </div>
                    </div>
                </div>
                <div className="testimonials-name title-h8">{testimonial.title}</div>
                <div className="testimonials-text desc-2">{testimonial.text}</div>
                <div className="testimonials-user">
                    <div className="testimonials-user__img">
                        <img src={icon ? icon : iconUser} alt="artist icon" />
                    </div>
                    <div className="testimonials-user__text desc-2">
                        <span>{testimonial.name}</span> <span>{testimonial.type}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
