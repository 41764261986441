

import { testimonialItem } from './tabItem';
import { TestimonialMobile } from './mobileList';
import { DesktopTestimonials } from './desktopList';
import { BreakpointsContextProvider, useBreakpointType } from 'app/services/breakpoints';
import { useInView } from 'react-intersection-observer';
import DelayedComponent from 'app/components/common/lazy/delayed';

type Props = {
    testimonials: testimonialItem[];
    copy: any;
};


export const TestimonialsTabs = (props: Props) => {
    return (
        <BreakpointsContextProvider>
            <TestimonialsContent {...props}/>
        </BreakpointsContextProvider>
    );
};

const TestimonialsContent = ({ testimonials, copy }: Props) => {
    const { isDesktop } = useBreakpointType();
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div className="testimonials-main" ref={ref}>
            {isDesktop
                ? <DesktopTestimonials testimonials={testimonials} copy={copy} inView={inView}/>
                : <DelayedComponent lazyPriority={10}>
                    <TestimonialMobile testimonials={testimonials} copy={copy}/>
                </DelayedComponent>
            }
        </div>
    );
};
