import { CountedNumber } from 'app/components/common/CountedNumber';
import { SpotifyIframe } from '../playerWrap';
import { testimonialItem } from '../tabItem';

type Props = {
    currentTestimonial: testimonialItem;
    copy: informationCopy;
    inView: boolean;
};

type informationCopy = {
    streams: string;
    listeners: string;
    song: string;
};

export const TestimonialInformation = ({ copy, currentTestimonial, inView }: Props) => {
    const { song, text, url, streams, listeners } = currentTestimonial;

    return (
        <div className="testimonials-information testimonials-desktop">
            <div className="testimonials-api">
                <div className="testimonials-api__item">
                    {inView && <SpotifyIframe src={url} />}
                </div>
            </div>
            <div className="testimonials-grid">
                <div className="testimonials-grid__item">
                    <p className="testimonials-grid__subtitle desc-3">{copy.song}</p>
                    <div className="testimonials-song">
                        <h6 className="testimonials-song__item testimonials-grid__title">
                            {song}
                        </h6>
                    </div>
                </div>
                <div className="testimonials-grid__item">
                    <p className="testimonials-grid__subtitle desc-3">{copy.streams}</p>
                    <div className="testimonials-streams testimonials-grid__title">
                        <CountedNumber num={streams}/>
                    </div>
                </div>
                <div className="testimonials-grid__item">
                    <p className="testimonials-grid__subtitle desc-3">{copy.listeners}</p>
                    <div className="testimonials-listeners testimonials-grid__title">
                        <CountedNumber num={listeners}/>
                    </div>
                </div>
            </div>
            <div className="testimonials-text">
                <p className="testimonials-text__item desc-2">“{text}”</p>
            </div>
        </div>
    );
};
